import React, { Component } from "react";
import points from "../assets/img/points.png";
import etoile from "../assets/img/etoile.png";

export default class Offres extends Component {
  render() {
    return (
      <div className="offres" id="offres">
        <div className="titres">
          <h1>
            <span className="surligner"> Offres </span>{" "}
          </h1>
          <h2>Des formules </h2>
          <h2 className="orange"> clés en main & modulables </h2>
        </div>

        <div className="offres-content">
          <div className="offre pro">
            <div className="stars">
              <div className="premier">
                <img src={etoile} alt="etoile"></img>
              </div>
            </div>
            <h3>Offre pro</h3>
            <ul>
              <li>
                Création ou refonte complète de votre site (jusqu’à 5 pages)
                <p className="italic">
                  Inclus : formulaire de contact & portail d'avis
                </p>
              </li>
              <li>Shooting photos (jusqu’à 10 photos)</li>
              <li>Création ou refonte de votre logo</li>
              <li>Formation à la gestion du site</li>
            </ul>
            <p>à partir de 2 500€</p>
            <a href="#contact">
              <button> En savoir plus </button>
            </a>
          </div>
          <div className="offre premium">
            <div className="stars">
              <div className="premier">
                <img src={etoile} alt="etoile"></img>
              </div>
              <div className="deuxieme">
                <img src={etoile} alt="etoile"></img>
              </div>
              <div className="troisieme">
                <img src={etoile} alt="etoile"></img>
              </div>
            </div>
            <h3>Offre premium</h3>
            <ul>
              <li>
                Création ou refonte complète de votre site (jusqu’à 15 pages)
                <p className="italic">
                  Inclus : formulaire de contact & portail d'avis
                </p>
              </li>
              <li>Shooting photos (jusqu’à 20 photos)</li>
              <li>Création ou refonte de votre logo</li>
              <li>
                Création ou refonte de votre identité sur les réseaux sociaux
                <p className="italic">
                  Inclus : Templates de posts Facebook, Instagram, Calendrier de
                  posts
                </p>
              </li>
              <li>Formation à la gestion du site et des réseaux sociaux </li>
            </ul>
            <p>à partir de 4 800€</p>
            <a href="#contact">
              <button> En savoir plus </button>
            </a>
          </div>
        </div>
        <div className="points">
          <img src={points} alt="points"></img>
        </div>
      </div>
    );
  }
}
