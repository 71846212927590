import React from "react";
import { useState } from "react";

const Formulaire = () => {
  const [nom, setName] = useState("");
  const [telephone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    let nameS = document.getElementById("input-nom");
    let emailS = document.getElementById("input-mail");
    let messageS = document.getElementById("input-message");
    let formMess = document.querySelector(".form-message");
    

    const isEmail = () => {
      let mailerrS = document.getElementById("mailerr");
      let regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

      if (email.match(regex)) {
        console.log("oui", email);
        mailerrS.style.display = "none";
        return true;
      } else {
        mailerrS.style.display = "block";
        mailerrS.style.animation = "dongle 1s";
        setTimeout(() => {
          mailerrS.style.animation = "none";
        }, 1000);
        console.log("non", email);
        return false;
      }
    };

    if (nom && isEmail() && message) {
      const templateId = "template_bu94l29";

      nameS.classList.remove("error");
      emailS.classList.remove("error");
      messageS.classList.remove("red");

      sendFeedback(templateId, {
        nom,
        telephone,
        email,
        message,
      });
    } else {
      formMess.innerHTML =
        "Merci de renseigner au moins un nom, un mail et un message";
      formMess.style.color = "red";

      if (!nom) {
        nameS.classList.add("error");
      } else {
        nameS.classList.remove("error");
      }
      if (!email) {
        emailS.classList.add("error");
      } else {
        emailS.classList.remove("error");
      }
      if (!message) {
        messageS.classList.add("error");
      } else {
        messageS.classList.remove("error");
      }
    }
  };

  const sendFeedback = (templateId, variables) => {
    let formMess = document.querySelector(".form-message");

    window.emailjs
      .send("mrgustave", templateId, variables)
      .then((res) => {
        formMess.innerHTML =
          "Votre message a bien été envoyé, nous vous répondrons au plus vite !";
        formMess.style.color = "green";

        document.getElementById("nom").classList.remove("error");
        document.getElementById("email").classList.remove("error");
        document.getElementById("message").classList.remove("error");
        setName("");
        setPhone("");
        setEmail("");
        setMessage("");

        setTimeout(() => {
          formMess.style.opacity = "0";
        }, 5000);
      })
      .catch(
        (err) =>
          (formMess.innerHTML =
            "Une erreur s'est produite, veuillez réessayer.")
      );
  };

  return (
    <div className="formulaire" id="contact">
      <h2>
        Nous <span>contact</span>er
      </h2>
      <div className="formulaire-content">
        <div className="texte">
          <h4>Vous avez un projet ?</h4>
          <h4>Ou souhaitez en savoir plus ?</h4>

          <p>
            {" "}
            N'hésitez pas à nous envoyer un message avec vos coordonnées et nous
            reviendrons vers vous dans les plus brefs délais pour voir comment
            nous pouvons vous accomagner !
          </p>

          <a href="mailto: bonjour@monsieurgustave.fr ">
            <div className="mail">
              <p> Remplissez le formulaire ou écrivez nous directement :</p>{" "}
              <p className="email">bonjour@monsieurgustave.fr</p>
            </div>
          </a>
        </div>
        <div className="form">
          <div className="form-input input-nom" id="input-nom">
            <input
              type="text"
              name="nom"
              placeholder="Nom"
              id="nom"
              required
              onChange={(e) => setName(e.target.value)}
              value={nom}
            />
          </div>
          <div className="email-content input-mail">
            <div className="form-input" id="input-mail">
              <input
                type="mail"
                id="email"
                placeholder="Mail"
                name="email"
                required
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </div>
            <label className="mailerr" id="mailerr">
              Cette adresse mail n'est pas valide
            </label>
          </div>
          <div className="form-input input-tel">
            <input
              type="text"
              id="telephone"
              placeholder="Tél"
              name="telephone"
              onChange={(e) => setPhone(e.target.value)}
              value={telephone}
            />
          </div>
          <div className="form-input input-message" id="input-message">
            <textarea
              id="message"
              name="message"
              placeholder="Bonjour, je suis gérant d’un hôtel et je souhaite…"
              onChange={(e) => setMessage(e.target.value)}
              value={message}
              required
            />
          </div>
          <input
            className="button hover"
            type="submit"
            value="Envoyer"
            onClick={handleSubmit}
          />
          <div className="form-message"></div>
        </div>
      </div>
    </div>
  );
};

export default Formulaire;
