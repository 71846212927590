import React, { Component } from 'react'

export default class Footer extends Component {
    render() {
        return (
            <div className="footer">
                <p>Copyright <i class="far fa-copyright"></i> 2021 Monsieur Gustave. Tous droits réservés. </p>
            </div>
        )
    }
}
