import React from 'react';
import Besoins from './components/Besoins';
import Description from './components/Description';
import Footer from './components/Footer';
import Formulaire from './components/Formulaire';
import Nav from './components/Nav';
import Offres from './components/Offres';
import ProjectList from './components/ProjectList';



const App = () => {
  return (
    <div>
      
      <Nav />
      <Description />
      <Offres />
      <Besoins />
      <ProjectList />
      <Formulaire />
      <Footer />
    </div>
  );
};

export default App;