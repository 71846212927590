import React from "react";
import img1 from "../assets/img/header.png";
import points from "../assets/img/points.png";
import logo from "../assets/img/logo.png";
import { useState } from "react";
import Scrollspy from 'react-scrollspy'

const Nav = () => {
  const [showLinks, setShowLinks] = useState(false);

  const handleShowLinks = () => {
    setShowLinks(!showLinks);
  };
  

  return (
    <div className="header">
      
      <nav className="nav-bar" id="menu">
        <div className="logo">
          <img src={logo} alt="monsieurgustave"></img>
        </div>
        <div className="menu-nav" >
          <ul>
          <Scrollspy
        items={ ['accueil', 'offres', 'realisations', 'contact'] }
        currentClassName="active" >
            <li>
              <a href="#accueil">Accueil</a>
            </li>
            <li>
              <a href="#offres">Offres</a>
            </li>
            <li>
              <a href="#realisations">Réalisations</a>
            </li>
            <li>
              <a href="#contact">Nous contacter</a>
            </li>
            </Scrollspy>
          </ul>
        </div>
      </nav>
      <div className="nav_mobile">
      <button
        className={`nav_burger ${showLinks ? "croix" : "burg"} `}
        onClick={handleShowLinks}
      >
        <span className="burger"></span>
      </button>
      <ul className={`menu_der ${showLinks ? "show_nav" : "hide_nav"} `}>
            <li>
              <a href="#accueil">Accueil</a>
            </li>
            <li>
              <a href="#offres">Offres</a>
            </li>
            <li>
              <a href="#realisations">Réalisations</a>
            </li>
            <li>
              <a href="#contact">Nous contacter</a>
            </li>
          </ul>
      </div>
      <div className="header-content" id="accueil">
        <div className="header-txt">
          <div className="titres">
            <h1>
              Agence digitale de <span className="mot">l'<span className="surligner"> hôtel</span>lerie </span> <span className="surligner">restau</span>
              ration
            </h1>
            <h4>
              {" "}
              Nous vous accompagnons dans la création de votre identité et
              votre présence en ligne.
            </h4>
            <p>
              {" "}
              Du shooting à votre propre site en passant par vos réseaux
              sociaux, Monsieur Gustave répond à tous vos besoins de création ou
              de refonte de votre image.
            </p>
            <a href="#contact">
            <button>Nous contacter</button>
            </a>
          </div>
          <div className="imgs">
            <img src={img1} alt="header"></img>
          </div>
        </div>
        
      </div>
      <div className="points">
          <img src={points} alt="points"></img>
        </div>
      <div className="fond"></div>
      
    </div>
  );
};

export default Nav;
