import hotel1 from "../assets/img/shooting/hotel.jpg";
import hotel2 from "../assets/img/shooting/hotel2.jpg";
import hotel3 from "../assets/img/shooting/hotel3.jpg";
import hotel4 from "../assets/img/shooting/hotel4.jpg";
import hotel5 from "../assets/img/shooting/hotel5.jpg";
import hotel6 from "../assets/img/shooting/hotel6.jpg";
import hotel7 from "../assets/img/shooting/hotel7.jpg";
import hotel8 from "../assets/img/shooting/hotel8.jpg";
import hotel9 from "../assets/img/shooting/hotel9.jpg";
import hotel10 from "../assets/img/shooting/hotel10.jpg";
import hotel11 from "../assets/img/shooting/hotel11.jpg";
import hotel12 from "../assets/img/shooting/hotel12.jpg";
import hotel13 from "../assets/img/shooting/hotel13.jpg";
import hotel14 from "../assets/img/shooting/hotel14.jpg";
import hotel15 from "../assets/img/shooting/hotel15.jpg";
import hotel16 from "../assets/img/shooting/hotel16.jpg";
import hotel17 from "../assets/img/shooting/hotel17.jpg";
import hotel18 from "../assets/img/shooting/hotel18.jpg";
import hotel19 from "../assets/img/shooting/hotel19.jpg";
import hotel20 from "../assets/img/shooting/hotel20.jpg";
import hotel21 from "../assets/img/shooting/hotel21.jpg";
import hotel22 from "../assets/img/shooting/hotel22.jpg";
import hotel23 from "../assets/img/shooting/hotel23.jpg";

export const portfolioData = [
  {
    id: 1,
    name: "La Tapiaz - Les Gets",
    filtre: ["Shooting", "Tout"],
    icons: ["fas fa-camera"],
    slider: [
      hotel1,
      hotel7,
      hotel21,
      hotel22,
      hotel23,
    ],
    lien: "www.google.fr",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam in magna at lectus dignissim malesuada. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Mauris ultricies id ipsum at varius",
    image: hotel1,
  },
  {
    id: 2,
    name: "Carré Noir - Porto Vecchio",
    filtre: ["Site", "Tout"],
    icons: ["far fa-file-code"],
    slider: [hotel2, hotel3, hotel4],
    lien: "www.google.fr",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam in magna at lectus dignissim malesuada. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Mauris ultricies id ipsum at varius",
    image: hotel2,
  },
  {
    id: 3,
    name: "Carré Noir - Porto Vecchio",
    filtre: ["Shooting", "Tout"],
    icons: ["fas fa-camera"],
    slider: [
      hotel2,
      hotel3,
      hotel4,
    ],
    lien: "www.google.fr",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam in magna at lectus dignissim malesuada. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Mauris ultricies id ipsum at varius",
    image: hotel3,
  },
  {
    id: 4,
    name: "Carré Noir - Porto Vecchio",
    filtre: ["Shooting", "Tout"],
    icons: ["fas fa-camera"],
    slider: [
      hotel2,
      hotel3,
      hotel4,
    ],
    lien: "www.google.fr",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam in magna at lectus dignissim malesuada. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Mauris ultricies id ipsum at varius",
    image: hotel4,
  },
  {
    id: 5,
    name: "L'Empire - Paris",
    filtre: ["Shooting", "Tout"],
    icons: ["fas fa-camera"],
    slider: [
      hotel5,
      hotel6,
    ],
    lien: "www.google.fr",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam in magna at lectus dignissim malesuada. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Mauris ultricies id ipsum at varius",
    image: hotel5,
  },
  {
    id: 6,
    name: "L'Empire - Paris",
    filtre: ["Shooting", "Tout"],
    icons: ["fas fa-camera"],
    slider: [
      hotel5,
      hotel6,
    ],
    lien: "www.google.fr",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam in magna at lectus dignissim malesuada. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Mauris ultricies id ipsum at varius",
    image: hotel6,
  },
  {
    id: 7,
    name: "La Tapiaz - Les Gets",
    filtre: ["Shooting", "Tout"],
    icons: ["fas fa-camera"],
    slider: [
      hotel1,
      hotel7,
      hotel21,
      hotel22,
      hotel23,
    ],
    lien: "www.google.fr",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam in magna at lectus dignissim malesuada. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Mauris ultricies id ipsum at varius",
    image: hotel7,
  },
  {
    id: 8,
    name: "Quality Suites - Lyon",
    filtre: ["Shooting", "Tout"],
    icons: ["fas fa-camera"],
    slider: [
      hotel8,
    ],
    lien: "www.google.fr",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam in magna at lectus dignissim malesuada. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Mauris ultricies id ipsum at varius",
    image: hotel8,
  },
  {
    id: 9,
    name: "Hotel George Washington - Paris",
    filtre: ["Shooting", "Tout"],
    icons: ["fas fa-camera"],
    slider: [
      hotel9,
    ],
    lien: "www.google.fr",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam in magna at lectus dignissim malesuada. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Mauris ultricies id ipsum at varius",
    image: hotel9,
  },
  {
    id: 10,
    name: "Nolinski - Paris",
    filtre: ["Shooting", "Tout"],
    icons: ["fas fa-camera"],
    slider: [
      hotel10,
      hotel11,
      hotel12,
      hotel13,
      hotel14,
      hotel15,
      hotel16,
      hotel17,
      hotel18,
      hotel19,
      hotel20,
    ],
    lien: "www.google.fr",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam in magna at lectus dignissim malesuada. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Mauris ultricies id ipsum at varius",
    image: hotel10,
  },
  {
    id: 11,
    name: "Nolinski - Paris",
    filtre: ["Shooting", "Tout"],
    icons: ["fas fa-camera"],
    slider: [
      hotel10,
      hotel11,
      hotel12,
      hotel13,
      hotel14,
      hotel15,
      hotel16,
      hotel17,
      hotel18,
      hotel19,
      hotel20,
    ],
    lien: "www.google.fr",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam in magna at lectus dignissim malesuada. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Mauris ultricies id ipsum at varius",
    image: hotel11,
  },
  {
    id: 12,
    name: "Nolinski - Paris",
    filtre: ["Shooting", "Tout"],
    icons: ["fas fa-camera"],
    slider: [
      hotel10,
      hotel11,
      hotel12,
      hotel13,
      hotel14,
      hotel15,
      hotel16,
      hotel17,
      hotel18,
      hotel19,
      hotel20,
    ],
    lien: "www.google.fr",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam in magna at lectus dignissim malesuada. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Mauris ultricies id ipsum at varius",
    image: hotel12,
  },
  {
    id: 13,
    name: "Nolinski - Paris",
    filtre: ["Shooting", "Tout"],
    icons: ["fas fa-camera"],
    slider: [
      hotel10,
      hotel11,
      hotel12,
      hotel13,
      hotel14,
      hotel15,
      hotel16,
      hotel17,
      hotel18,
      hotel19,
      hotel20,
    ],
    lien: "www.google.fr",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam in magna at lectus dignissim malesuada. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Mauris ultricies id ipsum at varius",
    image: hotel13,
  },
  {
    id: 14,
    name: "Nolinski - Paris",
    filtre: ["Shooting", "Tout"],
    icons: ["fas fa-camera"],
    slider: [
      hotel10,
      hotel11,
      hotel12,
      hotel13,
      hotel14,
      hotel15,
      hotel16,
      hotel17,
      hotel18,
      hotel19,
      hotel20,
    ],
    lien: "www.google.fr",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam in magna at lectus dignissim malesuada. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Mauris ultricies id ipsum at varius",
    image: hotel14,
  },
  {
    id: 15,
    name: "Nolinski - Paris",
    filtre: ["Shooting", "Tout"],
    icons: ["fas fa-camera"],
    slider: [
      hotel10,
      hotel11,
      hotel12,
      hotel13,
      hotel14,
      hotel15,
      hotel16,
      hotel17,
      hotel18,
      hotel19,
      hotel20,
    ],
    lien: "www.google.fr",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam in magna at lectus dignissim malesuada. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Mauris ultricies id ipsum at varius",
    image: hotel15,
  },
  {
    id: 16,
    name: "Nolinski - Paris",
    filtre: ["Shooting", "Tout"],
    icons: ["fas fa-camera"],
    slider: [
      hotel10,
      hotel11,
      hotel12,
      hotel13,
      hotel14,
      hotel15,
      hotel16,
      hotel17,
      hotel18,
      hotel19,
      hotel20,
    ],
    lien: "www.google.fr",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam in magna at lectus dignissim malesuada. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Mauris ultricies id ipsum at varius",
    image: hotel16,
  },
  {
    id: 17,
    name: "Nolinski - Paris",
    filtre: ["Shooting", "Tout"],
    icons: ["fas fa-camera"],
    slider: [
      hotel10,
      hotel11,
      hotel12,
      hotel13,
      hotel14,
      hotel15,
      hotel16,
      hotel17,
      hotel18,
      hotel19,
      hotel20,
    ],
    lien: "www.google.fr",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam in magna at lectus dignissim malesuada. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Mauris ultricies id ipsum at varius",
    image: hotel17,
  },
  {
    id: 18,
    name: "Nolinski - Paris",
    filtre: ["Shooting", "Tout"],
    icons: ["fas fa-camera"],
    slider: [
      hotel10,
      hotel11,
      hotel12,
      hotel13,
      hotel14,
      hotel15,
      hotel16,
      hotel17,
      hotel18,
      hotel19,
      hotel20,
    ],
    lien: "www.google.fr",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam in magna at lectus dignissim malesuada. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Mauris ultricies id ipsum at varius",
    image: hotel18,
  },
  {
    id: 19,
    name: "Nolinski - Paris",
    filtre: ["Shooting", "Tout"],
    icons: ["fas fa-camera"],
    slider: [
      hotel10,
      hotel11,
      hotel12,
      hotel13,
      hotel14,
      hotel15,
      hotel16,
      hotel17,
      hotel18,
      hotel19,
      hotel20,
    ],
    lien: "www.google.fr",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam in magna at lectus dignissim malesuada. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Mauris ultricies id ipsum at varius",
    image: hotel19,
  },
  {
    id: 20,
    name: "Nolinski - Paris",
    filtre: ["Shooting", "Tout"],
    icons: ["fas fa-camera"],
    slider: [
      hotel10,
      hotel11,
      hotel12,
      hotel13,
      hotel14,
      hotel15,
      hotel16,
      hotel17,
      hotel18,
      hotel19,
      hotel20,
    ],
    lien: "www.google.fr",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam in magna at lectus dignissim malesuada. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Mauris ultricies id ipsum at varius",
    image: hotel20,
  },
  {
    id: 21,
    name: "La Tapiaz - Les Gets",
    filtre: ["Shooting", "Tout"],
    icons: ["fas fa-camera"],
    slider: [
      hotel1,
      hotel7,
      hotel21,
      hotel22,
      hotel23,
    ],
    lien: "www.google.fr",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam in magna at lectus dignissim malesuada. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Mauris ultricies id ipsum at varius",
    image: hotel21,
  },
  {
    id: 22,
    name: "La Tapiaz - Les Gets",
    filtre: ["Shooting", "Tout"],
    icons: ["fas fa-camera"],
    slider: [
      hotel1,
      hotel7,
      hotel21,
      hotel22,
      hotel23,
    ],
    lien: "www.google.fr",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam in magna at lectus dignissim malesuada. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Mauris ultricies id ipsum at varius",
    image: hotel22,
  },
  {
    id: 23,
    name: "La Tapiaz - Les Gets",
    filtre: ["Shooting", "Tout"],
    icons: ["fas fa-camera"],
    slider: [
      hotel1,
      hotel7,
      hotel21,
      hotel22,
      hotel23,
    ],
    lien: "www.google.fr",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam in magna at lectus dignissim malesuada. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Mauris ultricies id ipsum at varius",
    image: hotel23,
  },
];
