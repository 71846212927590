import React from "react";
import { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';


export default function Réal(props) {
  const [showInfo, setShowinfo] = useState(false);

  const handleInfo = (status) => {
    setShowinfo((showInfo) => !showInfo);
    console.log(showInfo);
  };

  let { name, icons, lien, info, image, slider } = props.item;

  return (
    <div className={"projet " + name} >
      <div className="titre" /*onClick={handleInfo}*/>
        <h3>{name}</h3>
      </div>
      <img src={image} alt={name} /*onClick={handleInfo}*/></img>
      <div className="icons" /*onClick={handleInfo}*/>
        {icons.map((icon) => (
          <i className={icon} key={icon}></i>
        ))}
      </div>

       {showInfo ? (
        
        <div className="showinfos">
        <div className="infos-content">
          <h3>{name}</h3>
          <i class="far fa-times-circle" onClick={handleInfo}></i>
          <p>{info}</p>
          <a href={lien} target="_blank" rel="noopener noreferrer">
            Voir le site
          </a>
          <div className="slider">
          <Carousel
          infiniteLoop
          >
            {slider.map((image) => (
              <img src={image} key={image} alt={image}></img>
            ))}
            </Carousel>
          </div>              
        </div>
      </div>
        ) : <div> </div>
        }
    
      
    </div>
  );
}
