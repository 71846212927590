import React, { Component } from "react";
import { portfolioData } from "../data/portfolioData";
import Réal from "./Réal";
import gauche from "../assets/img/gauche.png";
import droite from "../assets/img/droite.png";


export default class ProjectList extends Component {
  state = {
    projects: portfolioData,
    radios: [
      { id: 1, value: "Identité visuelle" },
      { id: 2, value: "Site" },
      { id: 3, value: "Shooting" },
      { id: 4, value: "Tout" },
    ],
    selectedRadio: "Tout",
  };

  
  
  handleRadio = (event) => {
    let radio = event.target.value;
    this.setState({ selectedRadio: radio });
  };

  scrollleft = () => {
    document.getElementById("carou").scrollLeft += (window.innerWidth/1.5);
   
  };
  scrollright = () => {
    document.getElementById("carou").scrollLeft += -(window.innerWidth/1.5);
  };

  render() {
    let { projects, radios, selectedRadio } = this.state;

    return (
      <div className="realisation" id="realisations">
        <h2>  Nos <span>Réalisation</span>s </h2>
      
        <div className="realisations">
          <div className="choix">
            <ul className="radioDisplay">
              {radios.map((radio) => {
                return (
                  <li key={radio.id}>
                    <input
                      type="radio"
                      name="radio"
                      checked={radio.value === selectedRadio}
                      value={radio.value}
                      id={radio.value}
                      onChange={this.handleRadio}
                    />
                    <label htmlFor={radio.value}>{radio.value}</label>
                  </li>
                );
              })}
            </ul>
          </div>
          
          <div className="realisations-content" id="carou">
            {projects
              .filter((item) => item.filtre.includes(selectedRadio))
              .map((item) => {
                return <Réal key={item.id} item={item} />;
              })}
          </div>
        
        <div className="buttons">
          <span onClick={this.scrollright}><img src={gauche} alt="gauche"></img></span>
          <span onClick={this.scrollleft}><img src={droite} alt="droite"></img></span>
        </div>
      </div>
     
      </div>
      
    );
  }
}
