import React, { Component } from 'react';
import site from '../assets/img/site.png';
import identite from '../assets/img/identite.png';
import shooting from '../assets/img/shooting.png';
import communication from '../assets/img/communication.png';
import accompagnement from '../assets/img/accompagnement.png';

export default class Besoins extends Component {
    render() {
        return (
            <div className="besoins" id="besoins">
                <h2> Des réponses à <span className="orange"> tous vos besoins  </span></h2>
                <div className="besoin-content">
                    <div className="besoin site">
                        <div className="img">
                            <img src={site} alt="site"></img>
                        </div>
                        <div className="titre">
                            <h3>Site clé en main</h3>
                        </div>
                        <div className="txt">
                            <ul>
                                <li>Structure & Rédaction</li>
                                <li>des contenus</li>
                                <li>Fonctionnalités type</li>
                                <li><p>(contact, réservation, avis etc)</p></li>
                            </ul>
                        </div>
                    </div>
                    <div className="besoin identite">
                        <div className="img">
                        <img src={identite} alt="identite"></img>
                        </div>
                        <div className="titre">
                            <h3>Identité visuelle</h3>

                        </div>
                        <div className="txt">
                            <ul>
                                <li>Création ou refonte de votre logo </li>
                                <li>Création d’un univers graphique</li>
                                <li>déclinable sur tous vos supports </li>
                                <li><p>(menus, factures etc)</p></li>
                            </ul>

                        </div>
                    </div>
                    <div className="besoin shooting">
                        <div className="img">
                        <img src={shooting} alt="shooting"></img>
                        </div>
                        <div className="titre">
                            <h3>Shootings</h3>
                        </div>
                        <div className="txt">
                            <ul>
                                <li>Mises en situations</li>
                                <li>Prises de vues</li>
                                <li>Retouches</li>
                                <li>Cession de droits à vie</li>
                            </ul>

                        </div>
                    </div>
                    <div className="besoin communication">
                        <div className="img">
                        <img src={communication} alt="communication"></img>
                        </div>
                        <div className="titre">
                            <h3>Communication digitale</h3>
                        </div>
                        <div className="txt">
                            <ul>
                                <li>Création d’une identité et de templates </li>
                                <li>pour tous vos réseaux sociaux </li>
                                <li><p>(Instagram, Facebook, LinkedIn)</p></li> 
                                <li>Déclinaison sur les temps fort de l'année</li>
                            </ul>

                        </div>
                    </div>
                    <div className="besoin accompagnement">
                        <div className="img">
                        <img src={accompagnement} alt="accompagnement"></img>
                        </div>
                        <div className="titre">
                            <h3>Accompagnement personnalisé</h3>
                        </div>
                        <div className="txt">
                            <ul>
                                <li>Ecoute et analyse de vos besoins</li>
                                <li>Formation aux outils</li>
                                <li>pour une gestion autonome</li>
                                <li>Suivi et maintenance du site </li>
                            </ul>

                        </div>
                    </div>

                </div>
                
            </div>
        )
    }
}
