import React, { Component } from 'react';
import hotel from '../assets/img/hotel.png';
import restau from '../assets/img/restaurant.png';
import points from '../assets/img/points.png';


export default class Description extends Component {
    render() {
        return (
            <div className="description">
                <div className="description-content">
                    <h2> Une <span>communication moderne</span> pour <span>développer</span> votre image
                        et votre business
                    </h2>

                    <div className="texte">
                        <div className="colonne1">
                            <div className="txt">
                                <p className="gras"> Une communication en ligne soignée est indispensable. </p>
                                <p> <span className="gras"> 93% </span> des clients utilisent internet pour rechercher un <span>hôtel* </span>
                                    et <span className="gras">85%</span> des consommateurs recherchent des informations sur internet
                                    avant de se rendre au <span className="gras"> restaurant**.</span></p>
                                <p>Que ce soit sur des plateformes de réservation ou de commande en ligne ainsi
                                    que vos pages propres, le <span className="gras"> contenu </span> est scruté et <span className="gras"> comparé, </span> des photos aux menus
                                    en passant par l’univers graphique.
                                </p>
                            </div>
                            <img src={hotel} alt="hôtel"></img>
                        </div>
                        <div className="colonne2">
                            <img src={restau} alt="restaurant"></img>
                            <div className="txt">
                                <p> Afin de vous accompagner de la manière la plus <span className="gras"> simple et efficace </span>
                                    possible, <span className="orange"> Monsieur Gustave </span> vous propose des
                                    <span className="gras"> packs clés en main : </span>
                                    réalisation de votre site, des contenus (shootings photos et textes),
                                    création ou refonte de votre identité graphique, déclinaison sur les
                                    réseaux sociaux.
                                </p>
                                <p>
                                    Vous n’avez qu’un <span className="gras"> seul contact </span> qui s’occupe de toute votre communication
                                    en ligne, avec une offre et un accompagnement sur mesure, selon <span className="gras"> vos besoins</span>.
                                </p>
                            </div>

                            <p className="source"> *DGCCRF – JUILLET 2021 **étude Izipass - Janvier 2017
                            </p>

                        </div>

                    </div>
                    <div className="cadre">
                        <div className="cadreorange">
                        <h2>
                            Qui sommes nous ?
                        </h2>
                        </div>
                        <div className="txt">
                        <p className="gras"> Une équipe passionnée et experte du CHR* vous
                            accompagnera quelles que soient vos connaissances en communication.
                        </p>
                    
                        <p>
                            <span className="gras"> Monsieur Gustave</span> se veut une agence <span className="gras"> accessible
                                avec une offre transparente et claire.</span> Nous proposons
                            <span className="gras"> plusieurs offres </span>clés en main mais celles-ci
                            seront bien sûres adaptées à vos besoins, votre clientèle, vos problématiques
                            et vos ambitions.

                        </p>
                        
                        </div>
                        <p className="source">*Cafés/Hôtels/Restaurants
                        </p>

                    </div>
                </div>
                <div className="points">
                    <img src={points} alt="points"></img>
                
                </div>

            </div>
        )
    }
}
